<template>
  <div>
    <crud-button
      :onClickList="fetchTickets"
      :showAddNew="false"
    />
    <!-- <ticket-filters></ticket-filters> -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("tane göster") }}</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="ticketsTable"
        class="position-relative"
        :items="getTickets"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :per-page="perPage"
        :current-page="currentPage"
        empty-text="Kayıt bulunamadı"
      >
        <template #cell(ticketOncelikTuru)="data">
          <b-badge
              :variant="data.item.ticketOncelikTuru == 2 ? 'info' : 'danger'"
              class="badge"
            >
              <span>{{ resolveOncelikTur(data.item.ticketOncelikTuru) }}</span>
            </b-badge>
        </template>
         <template #cell(departmentTuru)="data">
          {{ resolveDepartmanTur(data.item.departmentTuru) }}
        </template>
        <template #cell(dtOlusTarih)="data">
          {{ formatDateToDMYT(data.item.dtOlusTarih) }}
        </template>
        
        <template #cell(actions)="data">
          <!-- Update -->
          <b-button
            size="sm"
            @click="
              $router.push({
                name: 'customer-support-detail',
                params: { detailId: data.item.id },
              })
            "
            variant="primary"
          >
            <span class="align-middle">{{ $t('Detay') }}</span>
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ dataMeta.of }} öğeden {{ dataMeta.from }} -
              {{ dataMeta.to }} arası gösteriliyor</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="toplamTicket"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import useSupport from './useSupport';

export default {

  setup() {
    const {
      fetchTickets,
      getTickets,
      tableColumns,
      perPage,
      currentPage,
      toplamTicket,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      ticketsTable,

      resolveDepartmanTur,
      resolveOncelikTur,

      formatDateToDMYT,
    } = useSupport();

    return {
      fetchTickets,
      getTickets,
      tableColumns,
      perPage,
      currentPage,
      toplamTicket,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      ticketsTable,

      resolveDepartmanTur,
      resolveOncelikTur,

      formatDateToDMYT,
    }
  },
}
</script>

<style>
</style>
