import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { formatDateToDMYT } from '@core/utils/filter';
import StaticOptions from '@/common/options/StaticOptions';

export default function useTickets() {
  const ticketsTable = ref(null)
  
  const tableColumns = [
    { key: 'sKayitNo', label: 'Kayıt No' },
    { key: 'sKonu', label: 'Konu' },
    { key: 'departmentTuru', label: 'Departman' },
    { key: 'ticketOncelikTuru', label: 'Öncelik', sortable: true },
    { key: 'sDbKullaniciAdi', label: 'Cloud Kullanıcı Adı', sortable: true },
    { key: 'dtOlusTarih', label: 'Ekleme Tarihi', sortable: true },
    { key: 'actions', label: 'Aksiyonlar' },
  ]
  const perPage = ref(10)
  const toplamTicket = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters['ticketCustomer/getTicketFilter'].sortBy
    },
    set(value) {
      store.commit('setTicketFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters['ticketCustomer/getTicketFilter'].sortDesc
    },
    set(value) {
      store.commit('setTicketFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = ticketsTable.value ? ticketsTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamTicket.value,
    }
  })

  const refetchData = () => {
    ticketsTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  function fetchTickets() {
    store.dispatch('ticketCustomer/fetchTickets', {})
  }

  const getTickets = computed(() => {
    const tickets = store.getters['ticketCustomer/getTickets']
    toplamTicket.value = tickets.length
    return tickets
  })

  onMounted(fetchTickets)

  function remove(id) {
    AlertService.confirmDelete(this, () => {
      this.$store.dispatch('ticketCustomer/removeTicket', id)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })

            this.fetchTickets();
          } else {
            AlertService.error(this, response.message)
          }
        });
    });
  }

  function resolveDepartmanTur(tur) {
    const result = StaticOptions.departmentTuruOptions.find(x => x.value === tur);
    return result ? result.label : ''
  }

  function resolveTicketUygulamaTur(tur) {
    const result = StaticOptions.ticketUygulamaTuruOptions.find(x => x.value === tur);
    return result ? result.label : ''
  }

  function resolveOncelikTur(tur) {
    return tur === 1 ? 'Acil' : 'Normal'
  }

  return {
    fetchTickets,
    getTickets,
    tableColumns,
    perPage,
    currentPage,
    toplamTicket,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    ticketsTable,
    
    resolveDepartmanTur,
    resolveTicketUygulamaTur,
    resolveOncelikTur,

    formatDateToDMYT,
  }
}
